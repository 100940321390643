var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"rounded-lg",attrs:{"elevation":_vm.$vuetify.breakpoint.mobile ? 8 : 0,"width":"100%"}},[_c('v-card-title',{staticClass:"form-title text-capitalize"},[_vm._v(" "+_vm._s(_vm.$t("resetPassword"))+" ")]),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"justify":"center","align":"center","lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-text-field',{ref:"email",staticClass:"label-fixed static",attrs:{"label":_vm.$t('passwordReset.fields.email'),"rules":[_vm.rules.isRequired, _vm.rules.isEmail],"disabled":true},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-text-field',{ref:"password",staticClass:"label-fixed static",attrs:{"append-icon":_vm.show.password ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.show.password ? 'text' : 'password',"value":"","label":_vm.$t('passwordReset.fields.newPassword'),"rules":[
								_vm.rules.isRequired,
								_vm.rules.isMinPassword,
								_vm.rules.isValidPassword,
							],"disabled":_vm.isLoading},on:{"click:append":function($event){_vm.show.password = !_vm.show.password}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-text-field',{ref:"password_confirmation",staticClass:"label-fixed static",attrs:{"append-icon":_vm.show.password_confirmation
									? 'mdi-eye'
									: 'mdi-eye-off',"type":_vm.show.password_confirmation
									? 'text'
									: 'password',"value":"","label":_vm.$t('passwordReset.fields.newPasswordConf'),"rules":[
								_vm.rules.isRequired,
								_vm.rules.isMinPassword,
								_vm.rules.isValidPassword,
								_vm.rules.isMatchingPasswords(
									_vm.password,
									_vm.password_confirmation
								),
							],"disabled":_vm.isLoading},on:{"click:append":function($event){_vm.show.password_confirmation =
									!_vm.show.password_confirmation}},model:{value:(_vm.password_confirmation),callback:function ($$v) {_vm.password_confirmation=$$v},expression:"password_confirmation"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('password-rules',{model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-card-actions',{staticClass:"mt-8"},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-btn',{staticClass:"px-16",attrs:{"block":_vm.$vuetify.breakpoint.xs,"color":"secondary","rounded":"","disabled":_vm.disabledSubmit,"loading":_vm.localLoading,"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t("passwordReset.actions.submit"))+" ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-btn',{attrs:{"plain":"","to":"/auth/login"}},[_vm._v(" "+_vm._s(_vm.$t("common.actions.goToLogin"))+" ")])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }

// @ts-ignore
import PasswordRules from "@/components/Content/Password/Rules/index.vue";
import {
	isRequired,
	isEmail,
	isMinPassword,
	isValidPassword,
	isMatchingPasswords,
	// @ts-ignore
} from "@/services/rule-services";
import { last } from "lodash";
import { mapActions, mapGetters } from "vuex";
import { isPasswordPassed } from "../../services/rule-services";
import { isEmpty } from "lodash";

export default {
	name: "ResetPassword",
	props: {},
	data: function () {
		return {
			localLoading: false,
			disabledSubmit: true,
			token: undefined,
			email: "",
			password: "",
			password_confirmation: "",
			valid: false,
			rules: {
				isRequired,
				isMinPassword,
				isEmail,
				isValidPassword,
				isMatchingPasswords,
			},
			show: {
				password: false,
				password_confirmation: false,
			},
		};
	},

	created() {
		this.$nextTick(async () => {
			document.title = this.$t("resetPassword");
			this.disabledSubmit = !isPasswordPassed(this.password);
		});
	},
	mounted() {
		this.setToken;
		this.$nextTick(async ()=> {
			await this.setEmail();
		});
	},
	computed: {
		...mapGetters("proccess", ["loading"]),
		setToken() {
			let pathArray: String[] = this.$route.path.split("/");
			const lastItem = last(pathArray);
			this.token = lastItem;
		},
		hasEmail() {
			return isEmpty(this.$route.query) ? false : !isEmpty(this.$route.query.email);
		},
		getEmail() {
			return this.$route.query.email;
		},
	},
	components: { PasswordRules },
	methods: {
		...mapActions("auth", ["resetPassword"]),

		async setEmail(){
			if(this.hasEmail){
				this.email = this.getEmail;
			}
		},
		async setLoading(load: Boolean = false) {
			this.localLoading = load;
		},
		async validate() {
			let form = this.$refs.form;
			return form.validate();
		},
		
		async handleSubmit() {
			try {
				if (!(await this.validate())) return;
				this.setLoading(true);
				await this.resetPassword({
					email: this.email,
					password: this.password,
					password_confirmation: this.password_confirmation,
					token: this.token,
				});
				this.setLoading(false);
				this.backToLogin();
			} catch (error) {
				this.setLoading(false);
			}
		},
		backToLogin() {
			this.$router.push("/auth/login");
		},
	},

	watch: {
		password(val: string) {
			this.disabledSubmit = !isPasswordPassed(val);
		},
		password_confirmation(val: string) {
			this.disabledSubmit = !isPasswordPassed(val);
		},
	},
};
